exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-assine-ja-tsx": () => import("./../../../src/pages/assine-ja.tsx" /* webpackChunkName: "component---src-pages-assine-ja-tsx" */),
  "component---src-pages-beneficios-tsx": () => import("./../../../src/pages/beneficios.tsx" /* webpackChunkName: "component---src-pages-beneficios-tsx" */),
  "component---src-pages-contato-tsx": () => import("./../../../src/pages/contato.tsx" /* webpackChunkName: "component---src-pages-contato-tsx" */),
  "component---src-pages-credenciamento-tsx": () => import("./../../../src/pages/credenciamento.tsx" /* webpackChunkName: "component---src-pages-credenciamento-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-parceiros-credenciados-tsx": () => import("./../../../src/pages/parceiros-credenciados.tsx" /* webpackChunkName: "component---src-pages-parceiros-credenciados-tsx" */),
  "component---src-pages-planos-tsx": () => import("./../../../src/pages/planos.tsx" /* webpackChunkName: "component---src-pages-planos-tsx" */),
  "component---src-pages-produtos-tsx": () => import("./../../../src/pages/produtos.tsx" /* webpackChunkName: "component---src-pages-produtos-tsx" */)
}

