import React, { ReactNode, Fragment, useState, useEffect } from "react";
import { HeadFC, Script } from "gatsby";
import { queryResult } from "../data/graphql/query";
import { getGatsbyImageNode } from "../../utils/getGatsbyImageNode";

import LayoutLibrary from "@flyvet/flyvet-react-library/dist/src/containers/layouts/layout-topbar-colorful-no-logo";
import WhatsappButtonText from "@flyvet/flyvet-react-library/dist/src/components/buttons/whatsapp/whatsapp-button-text";

import "../styles/variables.scss";

export type LayoutProps = {
  children: ReactNode;
};

export const Layout = ({ children }: any) => {
  const {
    companyName,
    city,
    logoTopbar,
    logoFooter,
    GA4,
    facebook,
    instagram,
    whats,
    whatsMessage,
    linkedin,
    landingSubtitle,
  } = queryResult();
  const [pageLabel, setPageLabel] = useState("unknown");

  useEffect(() => {
    let path = window.location.pathname;
    if (path === "/parceiros-credenciados") {
      window.location.href = "/";
    } else {
      setPageLabel(window.location.pathname);
    }
  }, []);

  const renderutton = (trackEvent: any) => [
    <WhatsappButtonText
      buttonText="Atendimento para clientes"
      whats={whats}
      whatsMsg={whatsMessage}
      containerLabel="stories"
    ></WhatsappButtonText>,
    <WhatsappButtonText
      buttonText="Atendimento para empresas"
      whats="5585994520624"
      whatsMsg="Olá! Vim pelo site e gostaria de saber mais sobre o atendimeto para empresas!"
      containerLabel="stories"
    ></WhatsappButtonText>,
  ];

  return (
    <Fragment>
      <LayoutLibrary
        renderButton={renderutton}
        logoTopbar={getGatsbyImageNode(
          logoTopbar,
          `Logo ${companyName}`,
          true,
          false,
          false
        )}
        logoFooter={getGatsbyImageNode(
          logoFooter,
          `Logo ${companyName}`,
          true,
          true,
          true
        )}
        companyName={companyName}
        city={city}
        slogan={landingSubtitle}
        badge={{ type: "vet" }}
        facebook={facebook}
        instagram={instagram.user}
        linkedin={linkedin}
        hasHighlightedLink
        highlightedLink="/assine-ja"
        highlightedText="Assine já"
        navItems={[
          {
            label: "Home",
            path: "/",
          },
          {
            label: "Planos",
            path: "/planos",
          },
          {
            label: "Credenciamento",
            path: "/credenciamento",
          },
          {
            label: "Benefícios",
            path: "/beneficios",
          },
          {
            label: "Produtos",
            path: "/produtos",
          },
          {
            label: "Contato",
            path: "/contato",
          },
        ]}
      >
        {children}
      </LayoutLibrary>
    </Fragment>
  );
};

export default Layout;

export const Head: HeadFC<{}> = ({}) => (
  <>
    <link
      key="nunito"
      rel="preload"
      href="/src/styles/fonts/nunito-v26-latin-regular.woff2"
      as="font"
      type="font/woff2"
      crossOrigin="anonymous"
    />
    ,
    <link
      key="titillium-web"
      rel="preload"
      href="/src/styles/fonts/titillium-web-v17-latin-regular.woff2"
      as="font"
      type="font/woff2"
      crossOrigin="anonymous"
    />
    ,
  </>
);
